.card-plain{
    background: transparent;
    box-shadow: none;
    

    .card-body,
    .card-header{
        padding-left: 5px;
        padding-right: 5px;
        
    }

    .card-header:after{
        width: 100%;
        left: 0;
    }
}

.card-about{
    background: #2c2c2c;
    box-shadow: none;
    opacity: 0.95;

    .card-about::before{
        opacity: 0.5;
    }

    .card-body,
    .card-header{
        padding-left: 5px;
        padding-right: 5px;
        opacity: 1.0;
    }

    .card-header:after{
        width: 100%;
        left: 0;
        opacity: 0.7;
    }
}
